<template>
  <div>
    <HomePageTop></HomePageTop>
    <section>
      <img alt="" src="../../assets/tittle(1).png" width="100%">
      <div class="explain">
        <div class="header">
          <div class="headerTitle">
            <div style="margin-bottom: 20px">平素は格別のご愛顧を賜り厚く御礼を申し上げます。</div>
            <div>弊社では下記日程の通り、元旦と旧正月のお休みについてご連絡させて頂きます。</div>
          </div>
          <div class="textList">
            <div style="margin-bottom: 10px">今年も多大なるご協力を頂きまして、心より感謝申し上げます。</div>
            <div style="margin-bottom: 10px">来年も更なるサービス向上を目指し、スタッフ一同誠心誠意努力して参ります。
            </div>
            <div>引き続き、RAKUMARTを何卒、宜しくお願い申し上げます。</div>
          </div>
          <div class="headerExplainContainer">
            <div class="left">
              <div class="title" style="width: 190px;">元旦休み 3連休</div>
              <div class="headerExplainList">
                <div class="newTitle">休業期間</div>
                <div class="date">2023年12月30日(土)～2024年1月1日(月)</div>
                <div class="newTitle">営業開始</div>
                <div>2024年1月2日(火)から業務再開</div>
              </div>
            </div>
            <div class="right">
              <div class="title" style="width: 220px;">旧正月 16日長連休</div>
              <div class="headerExplainList">
                <div class="newTitle">休業期間</div>
                <div class="date">2024年2月4日(日)～2024年2月19日(月)</div>
                <div class="newTitle">営業開始</div>
                <div>2024年2月20日(火)から業務再開</div>
              </div>
            </div>
          </div>
        </div>
        <div class="footer">
          <div class="footerTitle">注意事項</div>
          <div class="footerExplainContainer">
            <div style="margin-bottom: 10px">◆</div>
            <div class="footerExplainText" style="margin-bottom: 30px">
              長い旧正月連休に入るまで、順調な荷物出荷を処理する為、ご注文の受付は締切日はございませんが、
              例年の経験から1月初旬までに入金された注文書について、発送対応は可能とおもいますが、
              それ以降は出荷できないことがございますので、予めご了承ください。
            </div>
            <div class="footerExplainText" style="margin-bottom: 30px">
              例年の経験から、この時期以降は、流通が特に混雑し、
              各店舗休業を前倒しにしたり、在庫切れになる店舗も多数ある為、早めの在庫確保、
              特に定番商品は今から計画的に割増発注をお勧め致します。
              (※最終発注日～旧正月明け以降、初回荷物到着まで35~40日を要しますので、
              連休前の最終発注量は、約40日分の在庫確保をお勧めさせて頂きます。）
            </div>
            <div style="margin-bottom: 10px">◆</div>
            <div class="footerExplainText" style="margin-bottom: 30px">
              旧正月連休前後は、中国内流通、国際発送が混雑及び工場の稼働は基本２月25日以降が多いため、
              納期遅れなどが予想されますので、予めご了承ください。
            </div>
            <div style="margin-bottom: 10px">◆</div>
            <div class="footerExplainText">
              連休中に於いて、ご注文の受付はシステムにて見積り依頼の受付けしておりますが、
              見積・商品仕入れ・検品・出荷は2024年2月20日(火)
              より順次対応させていただきます。
            </div>
          </div>
        </div>
      </div>
    </section>
    <FootVue></FootVue>
  </div>
</template>
<script>
import HomePageTop from "../../components/head/HomePageTop";
import FootVue from "../../components/foot/Foot.vue";

export default {
  components: {
    HomePageTop,
    FootVue
  }
}
</script>

<style lang="scss" scoped>
.explain {
  background: url("../../assets/body.png") no-repeat;
  width: 100%;
  height: 1807px;
  background-size: 100% 100%;
  margin: -20px 0 30px 0;

  .header {
    margin: 0 auto;
    width: 807px;
  }

  .headerTitle {
    padding-top: 200px;
  }

  .textList {
    width: 100%;
    text-align: center;
    margin-top: 20px;
    line-height: 30px;
    color: #FFBB7A;
    font-weight: 600;
    font-size: 18px;
  }

  .headerExplainContainer {
    margin-top: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left, .right {
      width: 346px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .headerExplainList {
      font-size: 18px;
      color: #FFBB7A;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .newTitle {
      margin-bottom: 5px;
    }

    .date {
      margin-bottom: 20px;
    }

    .right {
      width: 342px;
    }

    .title {
      border-radius: 6px;
      border: 1px solid #FFBB7A;
      height: 50px;
      line-height: 50px;
      font-size: 20px;
      text-align: center;
      color: #FFBB7A;
      font-weight: 600;
      margin-bottom: 42px;
    }
  }

  .headerTitle div {
    color: #FFBB7A;
    font-weight: 600;
    font-size: 18px;
    text-align: center;
  }

  .footer {
    margin: 323px auto 0;
    width: 866px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .footerTitle {
    width: 190px;
    height: 50px;
    border: 1px solid #FFBB7A;
    border-radius: 6px;
    line-height: 50px;
    text-align: center;
    color: #FFBB7A;
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 42px;
  }

  .footerExplainContainer {
    color: #FFBB7A;
    font-size: 18px;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .footerExplainText {
    line-height: 30px;
  }
}
</style>